@import url(https://fonts.googleapis.com/css?family=Bree+Serif&display=swap);
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Bree Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url(/static/media/banner.9924009b.png);
    background-repeat: no-repeat;
    background-position: top center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 600px) {
    body {
        /* background-size: auto 50em; */
        background: none;
    }
}

@media screen and (max-width: 400px) {
    body {
        /* background-size: auto 30em; */
    }
}

.homePage {
  position: relative;
  background-image: url(/static/media/palma_1-0.9df9ca36.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 60px;
}
.homePage .above {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
@media screen and (max-width: 1421px) {
  .homePage {
    padding-top: 0;
  }
}
.homePage .under {
  padding-top: 30em;
}
@media screen and (max-width: 1199px) {
  .homePage .under {
    padding-top: 20em;
  }
}
@media screen and (max-width: 600px) {
  .homePage .under {
    padding-top: 0;
  }
}

.desktopMenu .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50em;
  margin: 0 auto;
  background-color: white;
  font-size: 20px;
  height: 4.5em;
  overflow: hidden;
}
.desktopMenu .menubar__logo {
  margin: 0 20px 0 40px;
}
.desktopMenu .menubar__logo img {
  cursor: pointer;
  margin-top: 0;
}
.desktopMenu .menubar .sticky-logo {
  margin: 0 30px 0 140px;
}
@media screen and (max-width: 1515px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 145px;
  }
}
@media screen and (max-width: 1420px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 20px;
  }
}
.desktopMenu .menubar__item {
  margin: 20px 15px 0 15px;
}
.desktopMenu .menubar__item p a {
  text-transform: uppercase;
}
.desktopMenu .menubar__item a:active,
.desktopMenu .menubar__item a:hover,
.desktopMenu .menubar__item a:visited,
.desktopMenu .menubar__item a:link {
  color: black;
  text-decoration: none;
}
.desktopMenu .menubar .sticky-item {
  margin: 20px 20px 0 20px;
}
@media screen and (max-width: 1650px) {
  .desktopMenu .menubar .sticky-item {
    margin: 20px 15px 0 15px;
  }
}
.desktopMenu .menubar__order {
  margin: 0 30px 0 30px;
}
.desktopMenu .menubar__order .order-button {
  color: #c94a54;
  border: 2px solid #c94a54;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.desktopMenu .menubar__order .order-button:hover {
  cursor: pointer;
}
.desktopMenu .menubar__langSel {
  margin: 15px 30px 0 30px;
}
.desktopMenu .menubar__langSel span {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton {
  display: none;
}
.desktopMenu .menubar__toggleButton:hover {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton i:before {
  font-size: 35px;
}
.desktopMenu .sticky {
  width: 100%;
}
@media screen and (max-width: 1425px) {
  .desktopMenu .menubar {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .desktopMenu .menubar {
    font-size: 1rem;
  }
  .desktopMenu .menubar__logo {
    margin: 0 0 0 20px;
  }
  .desktopMenu .menubar__logo img {
    max-width: 6rem;
  }
  .desktopMenu .menubar .sticky-logo {
    margin: 0 0 0 20px;
  }
  .desktopMenu .menubar__item {
    margin: 20px 20px 0 20px;
  }
  .desktopMenu .menubar__order {
    margin: 0 15px;
  }
  .desktopMenu .menubar__langSel {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin: 15px 15px 0 15px;
  }
}
@media screen and (max-width: 1049px) {
  .desktopMenu {
    display: none;
  }
}

/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/08/2019 12:26
  	*/

@font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.a5e45b73.eot);
    src: url(/static/media/Flaticon.a5e45b73.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Flaticon.fff989ff.woff2) format("woff2"), url(/static/media/Flaticon.c2f6a7fb.woff) format("woff"),
        url(/static/media/Flaticon.7e21b0ca.ttf) format("truetype"), url(/static/media/Flaticon.d1a46a11.svg#Flaticon) format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: "Flaticon";
        src: url(/static/media/Flaticon.d1a46a11.svg#Flaticon) format("svg");
    }
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 20px;
}

.flaticon-menu:before {
    content: "\f100";
}
.flaticon-information:before {
    content: "\f101";
}
.flaticon-twitter-logo-silhouette:before {
    content: "\f102";
}
.flaticon-instagram:before {
    content: "\f103";
}
.flaticon-facebook-logo:before {
    content: "\f104";
}
.flaticon-copyright:before {
    content: "\f105";
}

.mobileMenu {
  width: 100%;
  margin: 0 auto;
}
.mobileMenu .mobileMenu__header {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 5em;
}
.mobileMenu .mobileMenu__header .header__toggleButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
}
.mobileMenu .mobileMenu__header .header__toggleButton:hover {
  cursor: pointer;
}
.mobileMenu .mobileMenu__header .header__toggleButton i:before {
  font-size: 35px;
  margin-left: 0;
}
@media screen and (max-width: 400px) {
  .mobileMenu .mobileMenu__header {
    height: 3.5rem;
  }
  .mobileMenu .mobileMenu__header .header-logo img {
    cursor: pointer;
    max-width: 6rem;
    height: auto;
  }
  .mobileMenu .mobileMenu__header .header__toggleButton i:before {
    font-size: 20px;
  }
}
.mobileMenu .mobile-menubar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  font-size: 20px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item p a {
  text-transform: uppercase;
}
.mobileMenu .mobile-menubar .mobile-menubar__item a:active,
.mobileMenu .mobile-menubar .mobile-menubar__item a:hover,
.mobileMenu .mobile-menubar .mobile-menubar__item a:visited,
.mobileMenu .mobile-menubar .mobile-menubar__item a:link {
  color: black;
  text-decoration: none;
}
.mobileMenu .mobile-menubar .mobile-menubar__order {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button {
  color: #c94a54;
  border: 2px solid #c94a54;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button:hover {
  cursor: pointer;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel {
  margin: 10px 30px 10px 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel span {
  cursor: pointer;
}
@media screen and (min-width: 1050px) {
  .mobileMenu {
    display: none;
  }
}
.sticky {
  width: 100%;
}

.about-container {
  padding-top: 10em;
  padding-bottom: 10em;
}
.about-container .left-side {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .about-container .left-side img {
    width: 100%;
    height: auto;
  }
}
.about-container .right-side {
  margin-top: -280px;
}
.about-container .right-side .right-side__images .aboutImg img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .about-container .right-side .right-side__images .aboutImg {
    margin-top: 15px;
  }
}
.about-container .right-side .right-side__text {
  margin-top: 50px;
}
.about-container .right-side .right-side__text .about__banner {
  background-image: url(/static/media/rolunk_cimsor_bg.49a98ab6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: 35px;
  padding: 10px 5px 10px 30px;
  margin: 0 10px;
}
@media screen and (max-width: 620px) {
  .about-container .right-side .right-side__text .about__banner {
    font-size: 25px;
  }
}
.about-container .right-side .right-side__text .about__text {
  margin: 0 10px;
  padding: 10px 15px;
  font-size: 20px;
  background-color: white;
  text-align: justify;
}
@media screen and (min-width: 1200px) {
  .about-container .right-side {
    margin-right: 70px;
  }
}
@media screen and (max-width: 1199px) {
  .about-container .right-side {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .about-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.mealBox {
    display: inline-grid;
    grid-template-rows: repeat(2, 50%);
    grid-template-columns: repeat(5, 20%);
}
.mealBox .meal img {
    width: 100%;
    height: auto;
}
@media screen and (max-width: 780px) {
    .mealBox {
        grid-template-rows: repeat(5, 20%);
        grid-template-columns: repeat(2, 50%);
    }
}

.chefOffer .header {
    background-image: url(/static/media/sef_ajanlat_bg.3c406a43.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 100px;
}
.chefOffer .header .header__content .header__content__left-side {
    display: flex;
    justify-content: center;
}
.chefOffer .header .header__content .header__content__left-side .left-side__content {
    display: flex;
    justify-content: center;
    width: 100%;
}
.chefOffer
    .header
    .header__content
    .header__content__left-side
    .left-side__content
    .chefOffer__banner {
    background-image: url(/static/media/sef_ajanlat_cimsor_bg.7c98b6c9.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: 35px;
    width: 12em;
    padding: 20px 0px 15px 50px;
}
@media screen and (max-width: 620px) {
    .chefOffer
        .header
        .header__content
        .header__content__left-side
        .left-side__content
        .chefOffer__banner {
        width: 100%;
        font-size: 25px;
    }
}
.chefOffer .header .header__content .header__content__right-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    padding-top: 10px;
}
@media screen and (max-width: 1199px) {
    .chefOffer .header .header__content .header__content__right-side {
        justify-content: center;
        padding-top: 20px;
    }
}
@media screen and (max-width: 600px) {
    .chefOffer .header {
        padding-top: 40px;
        padding-bottom: 10px;
    }
}
.chefOffer__text p {
    text-align: justify;
}

.reservation {
  background-image: url(/static/media/belszin_steak_rozmaringos_zoldseges_ujburgonyaval.cfd7542f.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding-top: 6em;
  padding-bottom: 6em;
}
.reservation .reservation-box {
  display: flex;
  justify-content: center;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header {
  text-align: center;
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
  background-image: url(/static/media/asztalfoglalas_cimsor_bg.c74458d9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
    font-size: 25px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__detail {
  font-size: 12px;
  margin-top: -15px;
}
@media screen and (max-width: 400px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header {
    width: 100%;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form {
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input {
  border-radius: 0;
  padding: 30px 0 30px 20px;
  font-size: 20px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form #dateInput,
.reservation .reservation-box .reservation-box__content .reservation-box__form #peopleInput {
  padding: 30px 0 30px 0;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__form #dateInput,
  .reservation .reservation-box .reservation-box__content .reservation-box__form #peopleInput {
    margin-top: 5px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .fourthLine .opening-information {
  font-size: 14px;
  padding-top: 30px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box {
  margin-left: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton {
  width: 100%;
  color: white;
  font-size: 25px;
  border-radius: 25px;
  padding: 18px 10px;
  border: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#eb4046), to(#b7030a));
  background-image: linear-gradient(to bottom, #eb4046, #b7030a);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__icon {
  margin-right: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__text {
  font-size: 13px;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo:hover {
  cursor: pointer;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form--select {
  color: #495057;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form--select .form--select__control {
  font-size: 20px;
  padding: 9px 10px;
  border-radius: 0;
}
@media screen and (max-width: 410px) {
  .reservation .reservation-box .reservation-box__content {
    width: 100%;
  }
}

.weeklyMenu {
  background-image: url(/static/media/heti_menu_bg.edc6d0c5.png);
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 5em;
  padding-bottom: 10em;
}
.weeklyMenu .menu .menu__box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 80%;
  margin-left: 150px;
}
.weeklyMenu .menu .menu__box .menu__box__banner {
  background-image: url(/static/media/heti_menu_cimsor_bg.bb333fc8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 100%;
  padding: 10px 0px 0px 40px;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__price {
  font-size: 25px;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
    font-size: 25px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
  color: white;
  background-color: #2c2c2c;
  padding-top: 10px;
  min-width: 10em;
  text-align: center;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date .Date__day {
  font-size: 25px;
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
    height: 6em;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
  font-size: 20px;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    background-color: white;
    padding: 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 15px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 20px;
    margin-left: 0;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
    flex-direction: column;
  }
}
@media screen and (max-width: 1500px) {
  .weeklyMenu .menu .menu__box {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .weeklyMenu .menu .menu__box {
    width: 90%;
    margin: 0 auto;
  }
}

.footer {
    background-color: white;
    padding-top: 3em;
}
.footer .footer__logo {
    display: flex;
    justify-content: flex-end;
}
@media screen and (max-width: 1199px) {
    .footer .footer__logo {
        justify-content: center;
    }
}
.footer .footer__middleContent .footer__middleContent__above {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__title {
    font-size: 20px;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons {
    display: inline;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a {
    color: #212529;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons a:hover {
    color: #666;
}
.footer .footer__middleContent .footer__middleContent__above .middleContent__detailIcons i {
    margin-right: 20px;
}
@media screen and (max-width: 600px) {
    .footer .footer__middleContent .footer__middleContent__above {
        flex-direction: column;
    }
}
.footer .footer__middleContent .footer__middleContent__under {
    margin-top: 30px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon {
    margin-right: 5px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-icon
    i:before {
    font-size: 12px;
}
.footer
    .footer__middleContent
    .footer__middleContent__under
    .footer__middleContent__under__content
    .copyright-text {
    margin-top: 2px;
    margin-right: 25px;
    font-size: 14px;
}

.spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.spinner .lds-dual-ring {
    display: inline-block;
    width: 120px;
    height: 120px;
}
.spinner .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 120px;
    height: 120px;
    margin: 1px;
    border-radius: 50%;
    border: 15px solid #c94a54;
    border-color: #c94a54 transparent #c94a54 transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes lds-dual-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

body {
    background-image: none;
}
.menu-page--container {
    background-image: url(/static/media/banner_white.12aef364.png);
    background-repeat: no-repeat;
    background-position: top center;
}
.menu-page--container__back-link {
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    background-color: #c94a54;
    color: #fff;
    cursor: pointer;
    margin-left: 1rem;
}
.menu-page--container__back-link:hover {
    text-decoration: none;
}
.menu-page--container__back-link:focus {
    outline: none !important;
}
.menu-page--container__back-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--container .download {
    font-size: 1.2rem;
    padding: 1rem 0;
    display: block;
    background-color: #c94a54;
    border-radius: 2rem;
    border: 0.2rem solid #c94a54;
    max-width: 12rem;
    margin: 1rem auto 0 auto;
    color: #fff;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.menu-page--container .download:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--top-container {
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}
.menu-page--top-container img {
    max-width: 120px;
    height: auto;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.menu-page--pdf-container {
    padding: 2rem 4rem 2rem 4rem;
    margin: 0 auto;
    max-width: 85rem;
    text-align: center;
}
.menu-page--pdf-container .pdf-menu--container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator {
    cursor: pointer;
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    color: #c94a54;
    background-color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:hover {
    text-decoration: none;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:focus {
    outline: none !important;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:hover {
    background-color: #c94a54;
    color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator-sm,
.menu-page--pdf-container .pdf-menu--container .pdf-navigator-md {
    display: none;
}
.menu-page--pdf-container .pdf-menu--container .pdf-canvas {
    margin-top: 1rem;
    border-radius: 2rem;
    box-sizing: border-box;
    border: 8px solid #fff;
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.2);
}
.menu-page--pdf-container .pdf-menu--container .disabled {
    cursor: not-allowed;
    background-color: #d7d7d7;
    border-color: #d7d7d7;
    color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .disabled:hover {
    background-color: #d7d7d7;
    border-color: #d7d7d7;
    color: #fff;
}
.menu-page--online-ordering {
    background-image: url(/static/media/heti_menu_bg.edc6d0c5.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 600px);
    -webkit-transition: background-position 0.3s linear;
    transition: background-position 0.3s linear;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-page--online-ordering .info-box {
    background-color: rgba(255, 255, 255, 0.9);
    max-width: 30rem;
    min-height: 8rem;
    border-radius: 2rem;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.menu-page--online-ordering .info-box img {
    margin-bottom: 1rem;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.menu-page--online-ordering .info-box p {
    font-size: 22px;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}
.menu-page--online-ordering .order-button {
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    background-color: #c94a54;
    color: #fff;
    cursor: pointer;
}
.menu-page--online-ordering .order-button:hover {
    text-decoration: none;
}
.menu-page--online-ordering .order-button:focus {
    outline: none !important;
}
.menu-page--online-ordering .order-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--footer {
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #252525;
    color: #fff;
}
@media screen and (max-width: 1350px) {
    .menu-page--pdf-container .pdf-menu--container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .menu-page--pdf-container .pdf-menu--container .pdf-canvas {
        width: 100% !important;
        height: auto !important;
    }
    .menu-page--pdf-container .display-on-large-screen {
        display: none;
    }
    .menu-page--pdf-container .pdf-navigator-sm,
    .menu-page--pdf-container .pdf-navigator-md {
        width: 100%;
        justify-content: space-between;
        padding: 1.5rem 1rem 1rem 1rem;
    }
    .menu-page--pdf-container .pdf-navigator-md {
        display: flex !important;
    }
}
@media screen and (max-width: 768px) {
    .menu-page--pdf-container {
        padding: 6rem 1.5rem 2rem 1.5rem;
    }
    .menu-page--online-ordering {
        background-position: top right;
        padding-left: 0;
        padding-top: 0;
    }
    .menu-page--online-ordering .info-box p {
        font-size: 16px;
    }
    .menu-page--online-ordering .order-button {
        margin: 0 auto;
    }
}
@media screen and (max-width: 400px) {
    .menu-page--pdf-container .pdf-navigator-md {
        display: none !important;
    }
    .menu-page--pdf-container .pdf-navigator-sm {
        display: flex !important;
        flex-direction: column;
    }
    .menu-page--pdf-container .pdf-navigator-sm .pdf-navigator {
        width: 100%;
    }
    .menu-page--pdf-container .pdf-navigator-sm .pdf-navigator:last-of-type {
        margin-top: 1rem;
    }
}

.subscribe-view {
    background: url(/static/media/heti_menu_bg.edc6d0c5.png);
    min-height: 100vh;
    background-repeat: no-repeat;
}
.subscribe-view .subscribe-palma-logo {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    width: 200px;
    height: auto;
}
.subscribe-view .subscribe-content {
    background-color: white;
    padding: 4rem 2rem 2rem;
    box-shadow: 10px 10px 64px 0 rgba(0, 0, 0, 0.2);
}
.subscribe-view .subscribe-content__title {
    padding-top: 2rem;
    font-size: 1.8rem;
    padding-bottom: 2rem;
    text-align: center;
}
@media (max-width: 400px) {
    .subscribe-view .subscribe-content__title {
        font-size: 1.6rem;
    }
}
.subscribe-view .subscribe-checkbox {
    margin-top: 2rem;
    display: inline-block;
}
.subscribe-view .subscribe-checkbox-text {
    margin-bottom: 0;
}
.subscribe-view .subscribe-submit-button {
    background-color: black;
    color: white !important;
    text-align: center;
    width: 25rem;
    max-width: 100%;
    display: block;
    margin: 3rem auto;
    padding: 1rem 0;
    border-radius: 30px;
    cursor: pointer;
}
.subscribe-view .subscribe-submit-button:hover {
    background-color: #1a1a1a;
}
.subscribe-view .download-app-container {
    display: flex;
}
.subscribe-view .download-app-container img {
    width: 100%;
    padding: 0 0.5rem;
}
@media screen and (max-width: 576px) {
    .subscribe-view .download-app-container {
        flex-direction: column;
    }
}

.holiday-offer .under {
  padding-top: 10em;
}
.holiday-offer .holiday-offer-container {
  margin: 0 auto;
  max-width: 92rem;
  padding: 0 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.holiday-offer .holiday-offer-container .menu-image {
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 768px) {
  .holiday-offer .under {
    padding-top: 5em;
  }
  .holiday-offer .holiday-offer-container {
    padding: 0 0.5rem 2rem;
  }
}

