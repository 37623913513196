.homePage {
  position: relative;
  background-image: url("../../../../Assets/new/palma_1-0.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  padding-top: 60px;
}
.homePage .above {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}
@media screen and (max-width: 1421px) {
  .homePage {
    padding-top: 0;
  }
}
.homePage .under {
  padding-top: 30em;
}
@media screen and (max-width: 1199px) {
  .homePage .under {
    padding-top: 20em;
  }
}
@media screen and (max-width: 600px) {
  .homePage .under {
    padding-top: 0;
  }
}
