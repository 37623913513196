.mobileMenu {
  width: 100%;
  margin: 0 auto;
}
.mobileMenu .mobileMenu__header {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  height: 5em;
}
.mobileMenu .mobileMenu__header .header__toggleButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 2rem;
}
.mobileMenu .mobileMenu__header .header__toggleButton:hover {
  cursor: pointer;
}
.mobileMenu .mobileMenu__header .header__toggleButton i:before {
  font-size: 35px;
  margin-left: 0;
}
@media screen and (max-width: 400px) {
  .mobileMenu .mobileMenu__header {
    height: 3.5rem;
  }
  .mobileMenu .mobileMenu__header .header-logo img {
    cursor: pointer;
    max-width: 6rem;
    height: auto;
  }
  .mobileMenu .mobileMenu__header .header__toggleButton i:before {
    font-size: 20px;
  }
}
.mobileMenu .mobile-menubar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: white;
  font-size: 20px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__item p a {
  text-transform: uppercase;
}
.mobileMenu .mobile-menubar .mobile-menubar__item a:active,
.mobileMenu .mobile-menubar .mobile-menubar__item a:hover,
.mobileMenu .mobile-menubar .mobile-menubar__item a:visited,
.mobileMenu .mobile-menubar .mobile-menubar__item a:link {
  color: black;
  text-decoration: none;
}
.mobileMenu .mobile-menubar .mobile-menubar__order {
  margin: 10px 30px 0 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button {
  color: #c94a54;
  border: 2px solid #c94a54;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.mobileMenu .mobile-menubar .mobile-menubar__order .order-button:hover {
  cursor: pointer;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel {
  margin: 10px 30px 10px 30px;
}
.mobileMenu .mobile-menubar .mobile-menubar__langSel span {
  cursor: pointer;
}
@media screen and (min-width: 1050px) {
  .mobileMenu {
    display: none;
  }
}
.sticky {
  width: 100%;
}
