.reservation {
  background-image: url("../../../../Assets/new/belszin_steak_rozmaringos_zoldseges_ujburgonyaval.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding-top: 6em;
  padding-bottom: 6em;
}
.reservation .reservation-box {
  display: flex;
  justify-content: center;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header {
  text-align: center;
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
  background-image: url("../../../../Assets/asztalfoglalas_cimsor_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header .header__banner {
    font-size: 25px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__header .header__detail {
  font-size: 12px;
  margin-top: -15px;
}
@media screen and (max-width: 400px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__header {
    width: 100%;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form {
  color: white;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form input {
  border-radius: 0;
  padding: 30px 0 30px 20px;
  font-size: 20px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form #dateInput,
.reservation .reservation-box .reservation-box__content .reservation-box__form #peopleInput {
  padding: 30px 0 30px 0;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .reservation .reservation-box .reservation-box__content .reservation-box__form #dateInput,
  .reservation .reservation-box .reservation-box__content .reservation-box__form #peopleInput {
    margin-top: 5px;
  }
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .fourthLine .opening-information {
  font-size: 14px;
  padding-top: 30px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box {
  margin-left: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .submitButton {
  width: 100%;
  color: white;
  font-size: 25px;
  border-radius: 25px;
  padding: 18px 10px;
  border: 0;
  background-image: linear-gradient(to bottom, #eb4046, #b7030a);
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__icon {
  margin-right: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo .moreInfo__text {
  font-size: 13px;
  margin-top: 5px;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form .lastLine .submit-box .moreInfo:hover {
  cursor: pointer;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form--select {
  color: #495057;
}
.reservation .reservation-box .reservation-box__content .reservation-box__form--select .form--select__control {
  font-size: 20px;
  padding: 9px 10px;
  border-radius: 0;
}
@media screen and (max-width: 410px) {
  .reservation .reservation-box .reservation-box__content {
    width: 100%;
  }
}
