.mealBox {
    display: inline-grid;
    grid-template-rows: repeat(2, 50%);
    grid-template-columns: repeat(5, 20%);
}
.mealBox .meal img {
    width: 100%;
    height: auto;
}
@media screen and (max-width: 780px) {
    .mealBox {
        grid-template-rows: repeat(5, 20%);
        grid-template-columns: repeat(2, 50%);
    }
}
