.chefOffer .header {
    background-image: url("../../../../Assets/sef_ajanlat_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 100px;
}
.chefOffer .header .header__content .header__content__left-side {
    display: flex;
    justify-content: center;
}
.chefOffer .header .header__content .header__content__left-side .left-side__content {
    display: flex;
    justify-content: center;
    width: 100%;
}
.chefOffer
    .header
    .header__content
    .header__content__left-side
    .left-side__content
    .chefOffer__banner {
    background-image: url("../../../../Assets/sef_ajanlat_cimsor_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: 35px;
    width: 12em;
    padding: 20px 0px 15px 50px;
}
@media screen and (max-width: 620px) {
    .chefOffer
        .header
        .header__content
        .header__content__left-side
        .left-side__content
        .chefOffer__banner {
        width: 100%;
        font-size: 25px;
    }
}
.chefOffer .header .header__content .header__content__right-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    padding-top: 10px;
}
@media screen and (max-width: 1199px) {
    .chefOffer .header .header__content .header__content__right-side {
        justify-content: center;
        padding-top: 20px;
    }
}
@media screen and (max-width: 600px) {
    .chefOffer .header {
        padding-top: 40px;
        padding-bottom: 10px;
    }
}
.chefOffer__text p {
    text-align: justify;
}
