.about-container {
  padding-top: 10em;
  padding-bottom: 10em;
}
.about-container .left-side {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .about-container .left-side img {
    width: 100%;
    height: auto;
  }
}
.about-container .right-side {
  margin-top: -280px;
}
.about-container .right-side .right-side__images .aboutImg img {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 767px) {
  .about-container .right-side .right-side__images .aboutImg {
    margin-top: 15px;
  }
}
.about-container .right-side .right-side__text {
  margin-top: 50px;
}
.about-container .right-side .right-side__text .about__banner {
  background-image: url("../../../../Assets/rolunk_cimsor_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: 35px;
  padding: 10px 5px 10px 30px;
  margin: 0 10px;
}
@media screen and (max-width: 620px) {
  .about-container .right-side .right-side__text .about__banner {
    font-size: 25px;
  }
}
.about-container .right-side .right-side__text .about__text {
  margin: 0 10px;
  padding: 10px 15px;
  font-size: 20px;
  background-color: white;
  text-align: justify;
}
@media screen and (min-width: 1200px) {
  .about-container .right-side {
    margin-right: 70px;
  }
}
@media screen and (max-width: 1199px) {
  .about-container .right-side {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .about-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
