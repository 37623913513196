.holiday-offer .under {
  padding-top: 10em;
}
.holiday-offer .holiday-offer-container {
  margin: 0 auto;
  max-width: 92rem;
  padding: 0 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.holiday-offer .holiday-offer-container .menu-image {
  margin-bottom: 1rem;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 768px) {
  .holiday-offer .under {
    padding-top: 5em;
  }
  .holiday-offer .holiday-offer-container {
    padding: 0 0.5rem 2rem;
  }
}
