.subscribe-view {
    background: url("../../../../Assets/heti_menu_bg.png");
    min-height: 100vh;
    background-repeat: no-repeat;
}
.subscribe-view .subscribe-palma-logo {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    width: 200px;
    height: auto;
}
.subscribe-view .subscribe-content {
    background-color: white;
    padding: 4rem 2rem 2rem;
    -webkit-box-shadow: 10px 10px 64px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 64px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 10px 10px 64px 0 rgba(0, 0, 0, 0.2);
}
.subscribe-view .subscribe-content__title {
    padding-top: 2rem;
    font-size: 1.8rem;
    padding-bottom: 2rem;
    text-align: center;
}
@media (max-width: 400px) {
    .subscribe-view .subscribe-content__title {
        font-size: 1.6rem;
    }
}
.subscribe-view .subscribe-checkbox {
    margin-top: 2rem;
    display: inline-block;
}
.subscribe-view .subscribe-checkbox-text {
    margin-bottom: 0;
}
.subscribe-view .subscribe-submit-button {
    background-color: black;
    color: white !important;
    text-align: center;
    width: 25rem;
    max-width: 100%;
    display: block;
    margin: 3rem auto;
    padding: 1rem 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    cursor: pointer;
}
.subscribe-view .subscribe-submit-button:hover {
    background-color: #1a1a1a;
}
.subscribe-view .download-app-container {
    display: flex;
}
.subscribe-view .download-app-container img {
    width: 100%;
    padding: 0 0.5rem;
}
@media screen and (max-width: 576px) {
    .subscribe-view .download-app-container {
        flex-direction: column;
    }
}
