.desktopMenu .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50em;
  margin: 0 auto;
  background-color: white;
  font-size: 20px;
  height: 4.5em;
  overflow: hidden;
}
.desktopMenu .menubar__logo {
  margin: 0 20px 0 40px;
}
.desktopMenu .menubar__logo img {
  cursor: pointer;
  margin-top: 0;
}
.desktopMenu .menubar .sticky-logo {
  margin: 0 30px 0 140px;
}
@media screen and (max-width: 1515px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 145px;
  }
}
@media screen and (max-width: 1420px) {
  .desktopMenu .menubar .sticky-logo {
    margin: 0 30px 0 20px;
  }
}
.desktopMenu .menubar__item {
  margin: 20px 15px 0 15px;
}
.desktopMenu .menubar__item p a {
  text-transform: uppercase;
}
.desktopMenu .menubar__item a:active,
.desktopMenu .menubar__item a:hover,
.desktopMenu .menubar__item a:visited,
.desktopMenu .menubar__item a:link {
  color: black;
  text-decoration: none;
}
.desktopMenu .menubar .sticky-item {
  margin: 20px 20px 0 20px;
}
@media screen and (max-width: 1650px) {
  .desktopMenu .menubar .sticky-item {
    margin: 20px 15px 0 15px;
  }
}
.desktopMenu .menubar__order {
  margin: 0 30px 0 30px;
}
.desktopMenu .menubar__order .order-button {
  color: #c94a54;
  border: 2px solid #c94a54;
  padding: 5px 35px 5px 35px;
  border-radius: 25px 25px 25px 25px;
}
.desktopMenu .menubar__order .order-button:hover {
  cursor: pointer;
}
.desktopMenu .menubar__langSel {
  margin: 15px 30px 0 30px;
}
.desktopMenu .menubar__langSel span {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton {
  display: none;
}
.desktopMenu .menubar__toggleButton:hover {
  cursor: pointer;
}
.desktopMenu .menubar__toggleButton i:before {
  font-size: 35px;
}
.desktopMenu .sticky {
  width: 100%;
}
@media screen and (max-width: 1425px) {
  .desktopMenu .menubar {
    width: 100%;
  }
}
@media screen and (max-width: 1600px) {
  .desktopMenu .menubar {
    font-size: 1rem;
  }
  .desktopMenu .menubar__logo {
    margin: 0 0 0 20px;
  }
  .desktopMenu .menubar__logo img {
    max-width: 6rem;
  }
  .desktopMenu .menubar .sticky-logo {
    margin: 0 0 0 20px;
  }
  .desktopMenu .menubar__item {
    margin: 20px 20px 0 20px;
  }
  .desktopMenu .menubar__order {
    margin: 0 15px;
  }
  .desktopMenu .menubar__langSel {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    margin: 15px 15px 0 15px;
  }
}
@media screen and (max-width: 1049px) {
  .desktopMenu {
    display: none;
  }
}
