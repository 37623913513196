body {
    background-image: none;
}
.menu-page--container {
    background-image: url("../../../../Assets/banner_white.png");
    background-repeat: no-repeat;
    background-position: top center;
}
.menu-page--container__back-link {
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    background-color: #c94a54;
    color: #fff;
    cursor: pointer;
    margin-left: 1rem;
}
.menu-page--container__back-link:hover {
    text-decoration: none;
}
.menu-page--container__back-link:focus {
    outline: none !important;
}
.menu-page--container__back-link:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--container .download {
    font-size: 1.2rem;
    padding: 1rem 0;
    display: block;
    background-color: #c94a54;
    border-radius: 2rem;
    border: 0.2rem solid #c94a54;
    max-width: 12rem;
    margin: 1rem auto 0 auto;
    color: #fff;
    transition: all 0.2s linear;
}
.menu-page--container .download:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--top-container {
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}
.menu-page--top-container img {
    max-width: 120px;
    height: auto;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.menu-page--pdf-container {
    padding: 2rem 4rem 2rem 4rem;
    margin: 0 auto;
    max-width: 85rem;
    text-align: center;
}
.menu-page--pdf-container .pdf-menu--container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator {
    cursor: pointer;
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    color: #c94a54;
    background-color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:hover {
    text-decoration: none;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:focus {
    outline: none !important;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator:hover {
    background-color: #c94a54;
    color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .pdf-navigator-sm,
.menu-page--pdf-container .pdf-menu--container .pdf-navigator-md {
    display: none;
}
.menu-page--pdf-container .pdf-menu--container .pdf-canvas {
    margin-top: 1rem;
    border-radius: 2rem;
    box-sizing: border-box;
    border: 8px solid #fff;
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.2);
}
.menu-page--pdf-container .pdf-menu--container .disabled {
    cursor: not-allowed;
    background-color: #d7d7d7;
    border-color: #d7d7d7;
    color: #fff;
}
.menu-page--pdf-container .pdf-menu--container .disabled:hover {
    background-color: #d7d7d7;
    border-color: #d7d7d7;
    color: #fff;
}
.menu-page--online-ordering {
    background-image: url("../../../../Assets/heti_menu_bg.png");
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 600px);
    transition: background-position 0.3s linear;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-page--online-ordering .info-box {
    background-color: rgba(255, 255, 255, 0.9);
    max-width: 30rem;
    min-height: 8rem;
    border-radius: 2rem;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.menu-page--online-ordering .info-box img {
    margin-bottom: 1rem;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
.menu-page--online-ordering .info-box p {
    font-size: 22px;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}
.menu-page--online-ordering .order-button {
    border: 0.2rem solid #c94a54;
    padding: 0.5rem 2rem;
    transition: all 0.2s linear;
    border-radius: 2rem;
    text-decoration: none;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    outline: none !important;
    background-color: #c94a54;
    color: #fff;
    cursor: pointer;
}
.menu-page--online-ordering .order-button:hover {
    text-decoration: none;
}
.menu-page--online-ordering .order-button:focus {
    outline: none !important;
}
.menu-page--online-ordering .order-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: #c94a54;
    text-decoration: none;
}
.menu-page--footer {
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #252525;
    color: #fff;
}
@media screen and (max-width: 1350px) {
    .menu-page--pdf-container .pdf-menu--container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .menu-page--pdf-container .pdf-menu--container .pdf-canvas {
        width: 100% !important;
        height: auto !important;
    }
    .menu-page--pdf-container .display-on-large-screen {
        display: none;
    }
    .menu-page--pdf-container .pdf-navigator-sm,
    .menu-page--pdf-container .pdf-navigator-md {
        width: 100%;
        justify-content: space-between;
        padding: 1.5rem 1rem 1rem 1rem;
    }
    .menu-page--pdf-container .pdf-navigator-md {
        display: flex !important;
    }
}
@media screen and (max-width: 768px) {
    .menu-page--pdf-container {
        padding: 6rem 1.5rem 2rem 1.5rem;
    }
    .menu-page--online-ordering {
        background-position: top right;
        padding-left: 0;
        padding-top: 0;
    }
    .menu-page--online-ordering .info-box p {
        font-size: 16px;
    }
    .menu-page--online-ordering .order-button {
        margin: 0 auto;
    }
}
@media screen and (max-width: 400px) {
    .menu-page--pdf-container .pdf-navigator-md {
        display: none !important;
    }
    .menu-page--pdf-container .pdf-navigator-sm {
        display: flex !important;
        flex-direction: column;
    }
    .menu-page--pdf-container .pdf-navigator-sm .pdf-navigator {
        width: 100%;
    }
    .menu-page--pdf-container .pdf-navigator-sm .pdf-navigator:last-of-type {
        margin-top: 1rem;
    }
}
