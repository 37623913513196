.weeklyMenu {
  background-image: url("../../../../Assets/heti_menu_bg.png");
  background-repeat: no-repeat;
  background-position: top right;
  padding-top: 5em;
  padding-bottom: 10em;
}
.weeklyMenu .menu .menu__box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 80%;
  margin-left: 150px;
}
.weeklyMenu .menu .menu__box .menu__box__banner {
  background-image: url("../../../../Assets/heti_menu_cimsor_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 100%;
  padding: 10px 0px 0px 40px;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__price {
  font-size: 25px;
}
.weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
  font-size: 35px;
}
@media screen and (max-width: 620px) {
  .weeklyMenu .menu .menu__box .menu__box__banner .menu__title {
    font-size: 25px;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
  color: white;
  background-color: #2c2c2c;
  padding-top: 10px;
  min-width: 10em;
  text-align: center;
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date .Date__day {
  font-size: 25px;
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__Date {
    height: 6em;
  }
}
.weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
  font-size: 20px;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    background-color: white;
    padding: 0 10px;
  }
}
@media screen and (max-width: 500px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 15px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList .menuList__menu {
    font-size: 20px;
    margin-left: 0;
  }
}
@media screen and (max-width: 430px) {
  .weeklyMenu .menu .menu__box .menu__box__menuList .menuList {
    flex-direction: column;
  }
}
@media screen and (max-width: 1500px) {
  .weeklyMenu .menu .menu__box {
    width: 100%;
  }
}
@media screen and (max-width: 1199px) {
  .weeklyMenu .menu .menu__box {
    width: 90%;
    margin: 0 auto;
  }
}
