@import url("https://fonts.googleapis.com/css?family=Bree+Serif&display=swap");

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Bree Serif", serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("./Assets/banner.png");
    background-repeat: no-repeat;
    background-position: top center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 600px) {
    body {
        /* background-size: auto 50em; */
        background: none;
    }
}

@media screen and (max-width: 400px) {
    body {
        /* background-size: auto 30em; */
    }
}
